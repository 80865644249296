import { Expose } from 'class-transformer';

export class LinksDto {
  @Expose()
  tryNow!: string;

  @Expose()
  googlePlay!: string;

  @Expose()
  appStore!: string;
}
